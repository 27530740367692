<script lang="ts" setup>
import { MainMenuItem } from './useMainMenu'

const props = defineProps<{
  item: MainMenuItem
}>()

const columns = computed(() => props.item.items?.filter((item) => item?.items))
</script>
<template>
  <div id="submenu-{{ item.id }}" class="sub-menu container" :data-submenu="`submenu-${item.id}`">
    <ul class="columns cul grid">
      <li v-for="column in columns" :key="column.id" class="column tl-3">
        <SubMenuItems :items="column.items" />
      </li>
    </ul>
    <Button v-if="item.cta.url" secondary icon="offers" :to="item.cta.url" tabindex="0">
      {{ item.cta.text }}
    </Button>
  </div>
</template>

<style lang="scss" scoped>
.sub-menu {
  display: grid;
  grid-template-columns: 100%;
  gap: 48px;

  padding-block: 64px;
}

.button {
  justify-self: start;
}
</style>
